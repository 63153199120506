<template>
  <div class="ticket-order-detail">
    <w-dialog
      ref="detailDialogRef"
      title="门票订单详情"
      width="60%"
      btnWidth="140px"
      top="5vh"
      :hideFooter="true"
      class="order-detail"
    >
    <div class="basic-info">
      <el-descriptions :column="3" border>
        <el-descriptions-item
          v-for="(el, index) in basicInfo"
          :key="index"
          :label="el.label"
        >
          <span
            :style="{
              color: el.color ? el.color[el.value] : '',
            }"
            >{{ el.text ? el.text[el.value] : el.value }}</span
          ></el-descriptions-item
        >
      </el-descriptions>
      <div class="flex">
        <div class="remark">备注</div>
        <span class="text-black">{{ detailInfo.ot_remark }}</span>
      </div>
    </div>
      <div class="table-top-box">
        <div class="font-999">游客信息</div>
        <div class="download-operation">
          <div
            class="batch-upload ptb-16"
            @click="downloadTemp"
            v-if="authData.indexOf('o_4N5TayzYdievGpqXF38DRHJhPxWs') != -1 && detailInfo.is_download == '1'">
            下载导入模板
          </div>
          <el-upload
            v-if="authData.indexOf('o_e4lIgvNKcTVFaQdyMzwnZSUt2sRm') != -1 && detailInfo.is_upload == '1'"
            class="batch-upload"
            action=""
            ref="upload"
            :limit="1"
            :file-list="fileList"
            :show-file-list="false"
            :http-request="customUpload"
            accept=".xls,.xlsx"
          >
            <div class="batch-upload-text">批量导入</div>
          </el-upload>
        </div>
      </div>
      <common-table
        ref="peopleTableRef"
        :ischeck="false"
        :ispaging="false"
        :tableData="peopleData"
        :columns="peopleTableColumns"
        @openEditDialog="openEditDialog"
        @openStartrRefundDialog="openStartrRefundDialog"
      >
        <template #pass_status="{ row }">
          <span :style="'color:' + passStatusColors[row.pass_status]">{{
            ["", "未通行", "通行中", "已通行"][row.pass_status]
          }}</span>
        </template>
      </common-table>

      <br />
      <div class="font-999">景点分账明细</div>
      <common-table
        ref="accountTableRef"
        :ischeck="false"
        :ispaging="false"
        :tableData="accountData"
        :columns="accountTableColumns"
      >
      </common-table>
      <br />
      <div class="font-999">退款明细</div>
      <common-table
        ref="refundTableRef"
        :ischeck="false"
        :ispaging="false"
        :tableData="refundData"
        :columns="refundTableColumns"
      >
      </common-table>
    </w-dialog>
    <RefundDialog ref="initRefundRef" @submit="handleInitRefund"></RefundDialog>
    <EditTouristDialog
      ref="editTouristDialogRef"
      @submit="handleEditTourist"
    ></EditTouristDialog>
  </div>
</template>

<script>
import { ref, computed, watch, nextTick } from "vue";
import EditTouristDialog from "./EditTouristDialog.vue";
import RefundDialog from "../components/RefundDialog.vue";
import { OrderApi } from "@/plugins/api.js";
import { ElMessage } from "element-plus";
import axios from "axios";
import getBaseUrl from "@/plugins/baseUrl.js";
import { useStore } from "vuex";
import { getPassStatusColors } from "@/utils/common.js";

export default {
  emits: ["submit"],
  components: {
    EditTouristDialog,
    RefundDialog,
  },
  setup() {
    const baseUrl = getBaseUrl() ? getBaseUrl() : "";
    const passStatusColors = ref(getPassStatusColors());
    const detailDialogRef = ref(null);
    const basicInfo = ref({});
    const detailInfo = ref({});
    const store = useStore();
    const peopleData = ref([]);
    const accountData = ref([]);
    const refundData = ref([]);

    const menuTokens = computed(() => store.state.menuToken.menuTokens);
    const authData = ref([]);
    const peopleTableColumns = ref([
      {
        prop: "name",
        label: "游客名",
      },
      {
        prop: "mobile",
        label: "手机号",
      },
      {
        prop: "idcard",
        label: "身份证",
        minWidth: 140,
      },
      {
        prop: "pass_status",
        label: "通行状态",
        type: "customRender",
      },
      {
        type: "operation",
        prop: "",
        label: "操作",
        minWidth: 200,
        bottons: [
          {
            name: "编辑",
            action: "openEditDialog",
            token: "o_UKMgDJW21nEVPOwoaZ0lmIfAuhkQ",
            HiddenKey: "is_edit",
            showValue: [1],
            className: "theme-font-btn",
          },
          {
            name: "退款",
            action: "openStartrRefundDialog",
            token: "o_789OalFvc0XbJEpL4DqAMnd5RmoC",
            HiddenKey: "is_refund",
            showValue: [1],
            className: "black-font-btn",
          },
        ],
      },
    ]);

    const accountTableColumns = ref([
      {
        prop: "name",
        label: "景区名称",
      },
      {
        prop: "money",
        label: "分账金额",
      },
    ]);
    const refundTableColumns = ref([
      {
        prop: "refund_sn",
        label: "退款单号",
      },
      {
        prop: "refund_money",
        label: "退款金额",
      },
      {
        prop: "refund_payway_name",
        label: "退款方式",
      },
      {
        prop: "refund_confirm_user",
        label: "退款确认人",
      },
      {
        prop: "refund_confirm_time",
        label: "退款时间",
      },
      {
        prop: "refund_remark",
        label: "退款原因",
      },
    ]);
    watch(
      () => menuTokens.value,
      (data) => {
        if (data.length) {
          authData.value = data;
        }
      },
      {
        deep: true,
        immediate: true,
      }
    );
    function getDetail(type) {
      OrderApi.hotelTicketDetail({ sn: snId.value }).then((res) => {
        if (res.Code === 200) {
          detailInfo.value = res.Data;
          peopleData.value = res.Data.reservation || [];
          accountData.value = res.Data.ledger_account || [];
          refundData.value = res.Data.refund || [];
          peopleData.value.length &&
            peopleData.value.forEach((el) => {
              if (
                res.Data.pay_status == 2 &&
                detailInfo.value.ot_sale_way != 2 &&
                [3, 4].includes(res.Data.work_status)
              ) {
                el.detailStatus = 1;
              } else {
                el.detailStatus = 2;
              }
              if (
                detailInfo.value.pay_status == 2 &&
                el.is_permit_refund == 1 &&
                detailInfo.value.ot_sale_way != 2 &&
                detailInfo.value.or_type == 2
              ) {
                el.refundStatus = 1;
              } else {
                el.refundStatus = 2;
              }
            });

          basicInfo.value = [
            {
              label: "门票名称",
              value: detailInfo.value.ticket_name
                ? detailInfo.value.ticket_name
                : "-",
            },
            {
              label: "门票类型",
              value: detailInfo.value.ticket_type_name
                ? detailInfo.value.ticket_type_name
                : "-",
            },
            {
              label: "所属景区",
              value: detailInfo.value.scenic_name
                ? detailInfo.value.scenic_name
                : "-",
            },
            {
              label: "订单号码",
              value: detailInfo.value.sn ? detailInfo.value.sn : "-",
            },
            {
              label: "购买渠道",
              value: detailInfo.value.source_text
                ? detailInfo.value.source_text
                : "-",
            },
            {
              label: "支付方式",
              value: detailInfo.value.payway_name
                ? detailInfo.value.ar_name
                  ? detailInfo.value.payway_name +
                    "(" +
                    detailInfo.value.ar_name +
                    "-" +
                    detailInfo.value.ar_tourist +
                    ")"
                  : detailInfo.value.payway_name
                : "-",
            },
            {
              label: "下单时间",
              value: detailInfo.value.ctime ? detailInfo.value.ctime : "-",
            },
            {
              label: "购买数量",
              value: detailInfo.value.buy_num ? detailInfo.value.buy_num : "-",
            },
            {
              label: "支付金额",
              value: detailInfo.value.pay_money
                ? detailInfo.value.pay_money + "元"
                : "-",
            },
            {
              label: "支付时间",
              value: detailInfo.value.pay_time
                ? detailInfo.value.pay_time
                : "-",
            },
            {
              label: "退款数量",
              value: detailInfo.value.refund_num
                ? detailInfo.value.refund_num
                : "-",
            },

            {
              label: "退款金额",
              value:
                +detailInfo.value.refund_money > 0
                  ? detailInfo.value.refund_money + "元"
                  : "-",
            },
            {
              label: "优惠金额",
              value:
                +detailInfo.value.yh_money > 0
                  ? detailInfo.value.yh_money + "元"
                  : "-",
            },
            {
              label: "代金券",
              value:
                +detailInfo.value.coupon_money > 0
                  ? detailInfo.value.coupon_money + "元"
                  : "-",
            },
            {
              label: "支付状态",
              value: detailInfo.value.pay_status
                ? detailInfo.value.pay_status
                : "-",
              color: allStatusData.value?.payStatus?.colors,
              text: allStatusData.value?.payStatus?.options,
            },
            {
              label: "业务状态",
              value: detailInfo.value.work_status
                ? detailInfo.value.work_status
                : "-",
              color: allStatusData.value?.workStatus?.colors,
              text: allStatusData.value?.workStatus?.options,
            },

            {
              label: "销售模式",
              value: detailInfo.value.sale_way_text
                ? detailInfo.value.sale_way_text
                : "-",
            },
            {
              label: "分销商",
              value: detailInfo.value.agent_name
                ? detailInfo.value.agent_name
                : "-",
            },
            {
              label: "使用期限",
              value: detailInfo.value.sdate
                ? detailInfo.value.sdate + "至" + detailInfo.value.edate
                : "-",
            },
          ];

          type == 1 ? detailDialogRef.value.show() : "";
          nextTick(() => {
            if (peopleData.value.length != 0) {
              peopleTableRef.value.tableLoad();
            }
            if (accountData.value.length != 0) {
              accountTableRef.value.tableLoad();
            }
            if (refundData.value.length != 0) {
              refundTableRef.value.tableLoad();
            }
          });
        } else {
          let msg = res.Message ? res.Message : "获取详情失败！";
          ElMessage.error(msg);
        }
      });
    }
    const snId = ref(null);
    const peopleTableRef = ref(null);
    const accountTableRef = ref(null);
    const refundTableRef = ref(null);

    const allStatusData = ref({});
    function openDialog(id, data) {
      allStatusData.value = data;
      snId.value = id;
      getDetail(1);
    }
    function closeDialog() {
      detailDialogRef.value.close();
    }
    const editTouristDialogRef = ref(null);

    function openEditDialog(el) {
      editTouristDialogRef.value.openDialog(el);
    }
    function handleEditTourist(data) {
      const parmas = {
        idcard: data.idcard,
        realname: data.name,
        mobile: data.mobile,
        otr_id: data.id,
      };
      data.face_url ? (parmas["otr_face_url"] = data.face_url) : "";
      OrderApi.editTourist(parmas).then((res) => {
        editTouristDialogRef.value.closeDialogLoading();
        if (res.Code === 200) {
          ElMessage.success("编辑成功！");
          getDetail();
          editTouristDialogRef.value.closeDialog();
        } else {
          let msg = res.Message ? res.Message : "编辑失败！";
          ElMessage.error(msg);
        }
      });
    }

    function downloadTemp() {
      // const pathname = window.location.origin;
      OrderApi.exportTemplate().then(() => {
        const link = document.createElement("a");
        link.download = "游客数据模板";
        link.href = `${baseUrl}/order/order/export_reservation.api`;
        link.click();
        URL.revokeObjectURL(link.href);
      });
    }
    const fileList = ref([]);
    const upload = ref(null);
    function customUpload(params) {
      const fd = new FormData();
      fd.append("reservation", params.file);
      fd.append("otr_otid", detailInfo.value.ot_id);
      const url = `${baseUrl}/order/order/import_reservation.api`;
      const config = {
        transformRequest: [
          function (data) {
            return data;
          },
        ],
        headers: { "Content-Type": "multipart/form-data;charset=UTF-8" },
      };
      axios.post(url, fd, config).then(
        (res) => {
          if (res.status == 200) {
            if (res.data.Code == 200) {
              ElMessage.success("导入成功！");
              getDetail();
            } else {
              let msg = res.data.Message ? res.data.Message : "导入失败！";
              ElMessage.error(msg);
            }
          }
        },
        (error) => {
          let msg = error.Message ? error.Message : "导入失败！";
          fileList.value = [];
          ElMessage.error(msg);
        }
      );
    }
    const initRefundRef = ref(null);
    const refundPeopleId = ref(null);
    const openStartrRefundDialog = (row) => {
      refundPeopleId.value = row.id;
      const lastMoney =
        +detailInfo.value.pay_money - +detailInfo.value.refund_money;
      initRefundRef.value.openDialog("发起退款", lastMoney);
    };
    function handleInitRefund(data) {
      const parmas = {
        sn: snId.value,
        refund_mode: 2,
        otr_id: refundPeopleId.value,
        ...data,
      };
      OrderApi.initRefund(parmas).then((res) => {
        initRefundRef.value.closeDialogLoading();
        if (res.Code === 200) {
          ElMessage.success("操作成功");
          getDetail();
          initRefundRef.value.closeDialog();
        } else {
          let msg = res.Message ? res.Message : "操作失败！";
          ElMessage.error(msg);
        }
      });
    }
    return {
      closeDialog,
      basicInfo,
      detailDialogRef,
      openDialog,
      detailInfo,
      openEditDialog,
      editTouristDialogRef,
      handleEditTourist,
      downloadTemp,
      fileList,
      customUpload,
      upload,
      authData,
      peopleData,
      peopleTableRef,
      peopleTableColumns,
      accountTableRef,
      accountData,
      accountTableColumns,
      handleInitRefund,
      openStartrRefundDialog,
      initRefundRef,
      refundData,
      refundTableRef,
      refundTableColumns,
      passStatusColors,
    };
  },
};
</script>

<style lang="scss">
.ticket-order-detail {
  .order-detail {
    .el-dialog__body {
      padding-bottom: 200px;
    }
    .flex {
      display: flex;
    }
    .text-black {
      color: var(--text-color);
    }
    .basic-info {
      border-radius: 4px;
      margin-bottom: 30px;
      padding: 16px 30px 12px 30px;
      background-color: var(--search-uncheck-bg-color);
      .remark {
        color: var(--text-gray-color);
        width: 64px;
        flex-shrink:0;
      }
      .el-descriptions__body {
        border: none;
        background-color: var(--search-uncheck-bg-color);
        .el-descriptions__label,
        .el-descriptions__content {
          border: 1px solid transparent;
          background-color: var(--search-uncheck-bg-color);
        }
        .el-descriptions__label {
          width: 64px;
          color: var(--text-gray-color);
          font-weight: normal;
        }
        .el-descriptions__cell {
          padding: 0 0 4px 0;
        }
        .el-descriptions__content {
          color: var(--text-color);
          min-width: 100px;
        }
      }
    }
    .table-top-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .download-operation {
      display: flex;
      justify-content: space-between;
      .ptb-16 {
        padding: 2px 6px;
      }
      .batch-upload {
        text-align: center;
        background-color: #fff;
        border: 1px solid var(--theme-color);
        color: var(--theme-color);
        border-radius: 20px;
        cursor: pointer;
        margin-left: 10px;
        &:hover {
          background-color: var(--theme-color);
          color: #fff;
        }
        .el-upload {
          padding: 2px 6px;
          width: 100%;
        }
      }
    }
    .refund-desc {
      > span {
        color: var(--text-third-color);
      }
      .info {
        background-color: var(--search-uncheck-bg-color);
        padding: 16px 30px;
        border-radius: 4px;
        color: var(--text-gray-color);
      }
    }
  }
}
</style>
