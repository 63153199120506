<!-- 团体检票 -->

<template>
  <div class="refund-dialog">
    <w-dialog ref="dialogRef" title="团体检票" width="40%" top="25vh" @wConfirm="onConfirmBtn" confirmText="确认">
      <el-form ref="formRef" :model="formData" :rules="formRules" label-position="top">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item prop="sp_id" label="景点">
              <el-select v-model="formData.sp_id" placeholder="请选择景点" @change="attractionsChange">
                <el-option :label="item.label" :value="item.otb_spid" v-for="item in scenicOptions" :key="item.otb_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item prop="check_no" label="核销次数">
              <el-input v-model="formData.check_no" autocomplete="off" type="number" placeholder="请输入核销次数" clearable
                oninput="if(value<0)value=0">
              </el-input>
            </el-form-item>
            <p class="text-red">当前剩余可核销次数：{{ sumTotal }}次</p>
          </el-col>
        </el-row>
      </el-form>
    </w-dialog>
  </div>
</template>

<script>
  import { ref, nextTick } from "vue";
  import { OrderApi } from "@/plugins/api.js";
  import { ElMessage } from "element-plus";

  export default {
    emits: ["submit"],
    setup(props, { emit }) {
      // 景点选项
      const scenicOptions = ref([]);
      // 对话框对象
      const dialogRef = ref(null);
      // 表单对象
      const formRef = ref(null);
      // 表单数据
      const formData = ref({
        sp_id: '',  // 景点id
        check_no: '',  // 核销数量
        s_id: '',  // 景区id
        otb_id: '',  // 订单景区/景点通行记录ID
        action_type: 2,  // 1: 获取门票景区景点列表；2：核销
      });
      // 验证必填
      const formRules = {
        sp_id: [
          { required: true, message: "请选择景点", trigger: "blur", },
        ],
        check_no: [
          { required: true, message: "请输入核销次数", trigger: "blur", },
        ],
      };
      // 剩余可核销次数
      const sumTotal = ref(0);
      /**
        *
        * 打开对话框
        *
        **/
      function openDialog(sn) {
        OrderApi.checkGroupTicket({ action_type: 1, sn: sn }).then((res) => {
          if (res.Code === 200) {
            scenicOptions.value = res.Data;
            formData.value = {
              sp_id: '',  // 景点id
              check_no: '',  // 核销数量
              s_id: '',  // 景区id
              otb_id: '',  // 订单景区/景点通行记录ID
              action_type: 2,  // 1: 获取门票景区景点列表；2：核销
            };
            dialogRef.value.show();
          } else {
            ElMessage.error(res.Message);
          }
        });
      }
      /**
        *
        * 关闭对话框
        *
        **/
      function closeDialog() {
        dialogRef.value.close();
      }
      /**
        *
        * 对话框关闭按钮加载状态
        *
        **/
      function closeDialogLoading() {
        dialogRef.value.isLoading = false;
      }
      // 景点当前选中数据
      const selectedItems = ref('');
      /**
        *
        * 景点选择事件
        *
        **/
      function attractionsChange() {
        if (scenicOptions.value.length > 0) {
          scenicOptions.value.forEach(item => {
            if (item.otb_spid == formData.value.sp_id) {
              selectedItems.value = item;
            }
          })
        }
        // 可通行游玩次数 -1=无限次
        formData.value.check_no = selectedItems.value.otb_across_num == '-1' ? '1' : selectedItems.value.otb_across_num;
        sumTotal.value = selectedItems.value.otb_across_num == '-1' ? '无限' : selectedItems.value.otb_across_num;
        formData.value.s_id = selectedItems.value.sid;
        formData.value.otb_id = selectedItems.value.otb_id;
      }
      /**
        *
        * 确认按钮
        *
        **/
      function onConfirmBtn() {
        if (formRef.value) {
          formRef.value.validate((valid) => {
            if (valid) {
              dialogRef.value.isLoading = true;
              emit("submit", formData.value);
            }
          });
        }
      }
      return {
        dialogRef,
        openDialog,
        closeDialog,
        scenicOptions,
        sumTotal,
        closeDialogLoading,
        formData,
        formRules,
        formRef,
        onConfirmBtn,
        attractionsChange,
        selectedItems,
      };
    },
  };
</script>

<style lang="scss">
  .refund-dialog {

    .text-red {
      color: var(--error-color);
    }

    .el-input-group__append,
    .el-input-group__prepend {
      border: none;
      background-color: var(--search-uncheck-bg-color) !important;
      color: var(--text-gray-color);
    }

    .el-input-group__append {
      border-left: 1px solid #e6e3e3;
    }

    .img-upload>ul li {
      height: 40px;
      width: 40px;
      line-height: 40px;
    }

    .el-form-item {
      .el-form-item__content {
        .el-image {
          width: 40px !important;
          height: 40px !important;
        }
      }
    }
  }
</style>